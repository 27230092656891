<template>
    <div id="animod" :class="[priceModeCSS]" ref="app">
        <main id="main">
            <nuxt />
        </main>
    </div>
</template>

<script>
import priceModeMixin from '@/mixins/priceModesGlobal';
import authorizedLayout from '@/mixins/authorizedLayout';
import translatableLayout from '@/mixins/translatableLayout';
import partnerLayout from '@/mixins/partnerDOMLayout';
import iframeLayout, { middleware } from '@/mixins/iframeLayout';

export default {
    name: 'WeserkurierCombinedLayout',
    mixins: [priceModeMixin, authorizedLayout, translatableLayout, partnerLayout, iframeLayout],
    middleware(context) {
        middleware(context, 'weserkurier-frameset.html');
    },
    data() {
        return {
            iframeOffsetInParent: 200
        };
    },
    mounted() {
        this.parentAppendProductFooterHandler();
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

#animod {
    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        padding-top: 0;
    }
}
</style>
